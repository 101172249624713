const $careerHero = $('.career-hero');
let borderRadius;
if($(window).width() >= 900) {
    borderRadius = 1.78;
}
else {
    borderRadius = 1.5;
}

if($careerHero.length > 0) {
    $(window).scroll( function() {
        let scroll = $(window).scrollTop();
        if(scroll > 2 && scroll <= ($(window).height() + 80)) {
            $careerHero.find('.bg-color-overlay').css({ 'top' : 'calc((100% + 80px) - ' + scroll + 'px)', 'border-start-start-radius' : borderRadius + 'rem', 'border-start-end-radius' : borderRadius + 'rem' });
        }
        else if (scroll > $(window).height() + 80) {
            $careerHero.find('.bg-color-overlay').css({ 'top' : '0', 'border-radius' : 0 });
        }
        else {
            $careerHero.find('.bg-color-overlay').css({ 'top' : 'calc(100% + 80px)', 'border-start-start-radius' : borderRadius + 'rem', 'border-start-end-radius' : borderRadius + 'rem' });
        }
    });
}