const $newsBlock = $('.news-archive');
let $search;

if ($newsBlock.length > 0) {
    $search = $newsBlock.find('#search-input');
    $search.find('input').click(function () {
        $(this).closest('.search-wrapper').addClass('expand');
    });
}
else if($('body.search').length > 0) {
    $('header').removeClass('white-header');
    let $inputValue = $('body.search').find('input').val();
    $search = $('body.search').find('#search-input');
    $search.find('input').focus();
    $search.find('input').val('');
    $search.find('input').val($inputValue); 
}