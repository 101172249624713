let $managementBlock = $('.management');
let $arrow = $managementBlock.find('.info svg');
let $persons = $managementBlock.find('.persons .person');
let maxHeight = 0;

$persons.each(function() {
    if($(this).find('.info p').length > 1) {
        $(this).find('.info p').first().addClass('has-after');
        $(this).find('.info p:not(:first)').wrapAll('<div class="expandable-content"></div>');
    }
    else if ($(this).find('.info ul li').length > 1) {
        $(this).find('.info ul li:not(:first)').wrapAll('<div class="expandable-content"></div>');
    }
});

if($(window).width() >= 600) { 
    setTimeout(function() {
        $persons.each(function() {
            let thisHeight = $(this).height();
            if (thisHeight > maxHeight) { 
                maxHeight = thisHeight; 
            }
        });
        $persons.css({ 'min-height' : maxHeight });
    },100);
}

$arrow.click(function() {
    let $this = $(this);
    if($this.closest('.info').hasClass('open')) {
        $this.closest('.info').find('.expandable-content').slideUp(500);
        setTimeout(function() {
            $this.closest('.info').removeClass('open');
        }, 300);
    }
    else {
        $this.closest('.info').addClass('open');
        $this.closest('.info').find('.expandable-content').slideDown(500);
    }
});