import './_card';
import './_slideshow';
import './_mobile_menu';
import './_block-hero';
import './_menu-scroll';
import './_lazy-image';
//import './_strategy-list';
import './_scroll-to.js';
import './management';
import './_block-assets';
import './_block-career-hero';
import './search';
import './_block-key-numbers';
import './_block-image-archive';
import './_news-archive';

let $embed = $('section.embed');
if($embed.length > 0) {
    let $iframe = $embed.find('iframe');
    $embed.find('.wrapper').click(function() {
        if(!$iframe.hasClass('clicked')) {
            $iframe.addClass('clicked');
        }
    });
    $(document).click(function(event) { 
        let $target = $(event.target);
        if(!($target.is($embed.find('.wrapper')) || $target.is($iframe)) && $iframe.hasClass('clicked')){
            $iframe.removeClass('clicked');
        }
    });
}