const $imageBlock = $('section.image-archive');

if($imageBlock.length > 0) {
    let $categoriesContainer = $imageBlock.find('.images-filter .categories');
    let $toggleFilter = $imageBlock.find('.images-filter #toggle-filter');
    let $imagesWrapper = $imageBlock.find('.wrapper');
    let container = $imageBlock.find('.image-items')[0];
    let $listView = $imageBlock.find('.view-select a#list-view');
    let $imageView = $imageBlock.find('.view-select a#image-view');
    let $selectedCategories = [];
    let $currentPage = 1;
    let $initialLoad = true;
    let $mobile = 'false';
    if(window.innerWidth < 1200) {
        $mobile = 'true';
    }
    $categoriesContainer.find('li.active:not(.all)').each(function(index, item){
        $selectedCategories.push($(this).data('slug'));
    });

    $toggleFilter.click(function(e) {
        e.preventDefault();
        if(!$categoriesContainer.hasClass('open')) {
            $categoriesContainer.addClass('open');
        }
        else {
            $categoriesContainer.removeClass('open');
        }
    });

    $listView.click(function(e) {
        e.preventDefault();
        $imageView.removeClass('active');
        $(this).addClass('active');
        $imagesWrapper.removeClass('image-view');
        $imagesWrapper.addClass('list-view');
    });

    $imageView.click(function(e) {
        e.preventDefault();
        $listView.removeClass('active');
        $(this).addClass('active');
        $imagesWrapper.removeClass('list-view');
        $imagesWrapper.addClass('image-view');
    });

    function setActive(elem) {
        elem.addClass('active');
        elem.find('input')[0].checked = true;
        elem.find('input')[0].setAttribute('checked', 'true');
    }
    function removeActive(elem) {
        elem.removeClass('active');
        elem.find('input')[0].checked = false;
        elem.find('input')[0].setAttribute('checked', 'false');
    }

    $categoriesContainer.find('li').click(function (e) {
        e.preventDefault();
        $currentPage = 1;
        let slug = $(this).closest('li').data('slug');
        if(slug == 'all') {
            if(!$(this).closest('li').hasClass('active')) {
                $categoriesContainer.find('li').addClass('active');
                $categoriesContainer.find('li input').each(function(index, item) {
                    item.checked = true;
                    item.setAttribute('checked', 'true');
                });
                $categories = $imagesWrapper.data('categories').split(',');
                $selectedCategories = $categories;
            }
            else {
                $categoriesContainer.find('li').removeClass('active');
                $categoriesContainer.find('li input').each(function(index, item) {
                    item.checked = false;
                    item.setAttribute('checked', 'false');
                });
                $selectedCategories = [];
            }
        }
        else {
            if(!$(this).closest('li').hasClass('active')) {
                setActive($(this).closest('li'));
            }
            else {
                removeActive($(this).closest('li'));
            }

            if($categoriesContainer.find('li.active:not(.all)').length == $categoriesContainer.find('li:not(.all)').length) {
                setActive($categoriesContainer.find('li.all'));
            }
            else if($categoriesContainer.find('li.active:not(.all)').length < $categoriesContainer.find('li:not(.all)').length) {
                removeActive($categoriesContainer.find('li.all'));
            }
            $selectedCategories = [];
            $categoriesContainer.find('li.active:not(.all)').each(function(index, item){
                $selectedCategories.push($(this).data('slug'));
            });
        }
        getPosts();
    });

    function setPagination() {
        $imageBlock.find('.pagination .page-numbers li a').click(function(e) {
            e.preventDefault();
            $currentPage = $(this).data('slug');
            getPosts();
        });
        $imageBlock.find('.pagination li.prev a').click(function(e) {
            e.preventDefault();
            $currentPage -= 1;
            getPosts();
        });
        $imageBlock.find('.pagination li.next a').click(function(e) {
            e.preventDefault();
            $currentPage += 1;
            getPosts();
        });
        if($currentPage > 1) {
            $imageBlock.find('.pagination li.prev').removeClass('disabled');
            $imageBlock.find('.pagination li.prev a').removeClass('disabled');
        }
        if($currentPage == $imageBlock.find('.pagination .page-numbers li').length) {
            $imageBlock.find('.pagination li.next').addClass('disabled');
            $imageBlock.find('.pagination li.next a').addClass('disabled');
        }
        $imageBlock.find('.pagination .page-numbers li').removeClass('current');
        if($currentPage > 7) {
            $imageBlock.find('.pagination .page-numbers li').eq(6).addClass('current');
        }
        else {
            $imageBlock.find('.pagination .page-numbers li').eq($currentPage - 1).addClass('current');
        }
    }

    function getPosts() {
        let data = new FormData();
        data.append('action', 'get_images');
        data.append('page', $currentPage);
        data.append('mobile', $mobile);
        data.append('categories', $selectedCategories);
        $.ajax({
            type: 'POST',
            url: sval_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                container.innerHTML = res;
                setPagination();
                if(!$initialLoad) {
                    $(window).scrollTop($imageBlock.offset().top);
                }
                else {
                    $initialLoad = false;
                }
                $imageBlock.find('.wrapper .item .img-item').hover(function() {
                    $(this).addClass('hovering');
                }, function() {
                    $(this).removeClass('hovering');
                });
                $imageBlock.find('.wrapper .item .image-wrapper').hover(function() {
                    $(this).parent('.img-item').removeClass('hovering');
                });
            }
        });
    }
    getPosts();
}