const $assetsBlock = $('section.block-assets');
let searchText = ""; 
let textDisplayed = false;

if($assetsBlock.length > 0) {
    let $categoriesContainer = $assetsBlock.find('.assets-filter .categories');
    let $toggleFilter = $assetsBlock.find('.assets-filter #toggle-filter');
    let $assetsWrapper = $assetsBlock.find('.assets-wrapper');
    let $listView = $assetsBlock.find('.view-select a#list-view');
    let $cardView = $assetsBlock.find('.view-select a#card-view');
    let $assetFields = $assetsBlock.find('.asset-fields');
    let $selectedCategories = [];
    let $shownArticles = [];
    let $assets = $assetsBlock.find('.tease');
    $categoriesContainer.find('li.active:not(.all)').each(function(index, item){
        $selectedCategories.push($(this).data('slug'));
    });

    $toggleFilter.click(function(e) {
        e.preventDefault();
        if(!$categoriesContainer.hasClass('open')) {
            $categoriesContainer.addClass('open');
        }
        else {
            $categoriesContainer.removeClass('open');
        }
    });

    $listView.click(function(e) {
        e.preventDefault();
        $cardView.removeClass('active');
        $(this).addClass('active');
        $assetsWrapper.removeClass('card-view');
        $assetsWrapper.addClass('list-view');
    });

    $cardView.click(function(e) {
        e.preventDefault();
        $listView.removeClass('active');
        $(this).addClass('active');
        $assetsWrapper.removeClass('list-view');
        $assetsWrapper.addClass('card-view');
    });

    $categoriesContainer.find('li').keydown(function(e) {
        if (e.key == 'Enter') {
            let slug = $(this).closest('li').data('slug');
            if(slug == 'all') {
                if(!$(this).closest('li').hasClass('active')) {
                    $categoriesContainer.find('li').addClass('active');
                    $categoriesContainer.find('li input').each(function(index, item) {
                        item.checked = true;
                        item.setAttribute('checked', 'true');
                    });
                }
                else {
                    $categoriesContainer.find('li').removeClass('active');
                    $categoriesContainer.find('li input').each(function(index, item) {
                        item.checked = false;
                        item.setAttribute('checked', 'false');
                    });
                }
            }
            else {
                if(!$(this).closest('li').hasClass('active')) {
                    setActive($(this).closest('li'));
                }
                else {
                    removeActive($(this).closest('li'));
                }
    
                if($categoriesContainer.find('li.active:not(.all)').length == $categoriesContainer.find('li:not(.all)').length) {
                    setActive($categoriesContainer.find('li.all'));
                }
                else if($categoriesContainer.find('li.active:not(.all)').length < $categoriesContainer.find('li:not(.all)').length) {
                    removeActive($categoriesContainer.find('li.all'));
                }
            }
        }
    });

    $categoriesContainer.find('li').click(function (e) {
        e.preventDefault();
        let slug = $(this).closest('li').data('slug');
        if(slug == 'all') {
            if(!$(this).closest('li').hasClass('active')) {
                $categoriesContainer.find('li').addClass('active');
                $categoriesContainer.find('li input').each(function(index, item) {
                    item.checked = true;
                    item.setAttribute('checked', 'true');
                });
                $categories = $assetsWrapper.data('categories').split(',');
                $selectedCategories = $categories;
            }
            else {
                $categoriesContainer.find('li').removeClass('active');
                $categoriesContainer.find('li input').each(function(index, item) {
                    item.checked = false;
                    item.setAttribute('checked', 'false');
                });
                $selectedCategories = [];
            }
        }
        else {
            if(!$(this).closest('li').hasClass('active')) {
                setActive($(this).closest('li'));
            }
            else {
                removeActive($(this).closest('li'));
            }

            if($categoriesContainer.find('li.active:not(.all)').length == $categoriesContainer.find('li:not(.all)').length) {
                setActive($categoriesContainer.find('li.all'));
            }
            else if($categoriesContainer.find('li.active:not(.all)').length < $categoriesContainer.find('li:not(.all)').length) {
                removeActive($categoriesContainer.find('li.all'));
            }
            $selectedCategories = [];
            $categoriesContainer.find('li.active:not(.all)').each(function(index, item){
                $selectedCategories.push($(this).data('slug'));
            });
        }
        filterArticles();
    });

    $('#assets-search').keyup(function (e) {
        if (e.key == 'Enter') {
            searchText = e.target.value.toLowerCase();
            filterArticles();
        }
        else if(e.target.value.toLowerCase() == '') {
            searchText = e.target.value.toLowerCase();
            filterArticles();
        }
    });
    $('#search-input button').click(function(e) {
        e.preventDefault();
        searchText =  $('#assets-search')[0].value.toLowerCase();
        filterArticles();
    });

    function setActive(elem) {
        elem.addClass('active');
        elem.find('input')[0].checked = true;
        elem.find('input')[0].setAttribute('checked', 'true');
    }
    function removeActive(elem) {
        elem.removeClass('active');
        elem.find('input')[0].checked = false;
        elem.find('input')[0].setAttribute('checked', 'false');
    }

    function filterArticles() {
        $shownArticles = [];
        $('article.tease').removeClass('show');
        $('article.tease').hide();
        if($selectedCategories && $selectedCategories.length > 0) {
            $selectedCategories.forEach(function(slug) {
                $articles = $('article.tease.' + slug.trim());
                $shownArticles.push($articles);
            });
        }
        filterCheck($shownArticles);
    }

    function filterCheck($shownArticles) {
        for (let i = 0; i < $shownArticles.length; i++) {
            $shownArticles[i].each(function(index, item) {
                let $item = $(item);
                if(searchText != ''){
                    if ($item.find('.h2').text().toLowerCase().indexOf(searchText) !== -1) {
                        $item.addClass('show');
                        $item.show();
                    }
                }
                else {
                    $item.addClass('show');
                    $item.show();
                }
            });
        }
        $assetFields.each(function(index, item){
            let $item = $(item);
            let operables = $item.find('.operables .tease.show');
            let partners = $item.find('.partners .tease.show');
            $item.parent().removeClass('hide');
            $item.removeClass('hide merge');
            $item.prev('.title-wrapper').removeClass('hide');
            if(operables.length == 0) {
                $item.find('.operables').addClass('hide');
            }
            else {
                $item.find('.operables').removeClass('hide');
            }
            if(partners.length == 0) {
                $item.find('.partners').addClass('hide');
            }
            else {
                $item.find('.partners').removeClass('hide');
            }
            if(operables.length == 0 && partners.length == 0) {
                $item.addClass('hide');
                $item.prev('.title-wrapper').addClass('hide');
                $item.parent().addClass('hide');
            }
            else if(operables.length == 1 && partners.length == 1) {
                $item.addClass('merge');
            }
        });
        if($('.tease.show').length == 0 && !textDisplayed) {
            $assetsWrapper.append('<h3 class="no-articles-info">No results</h3>');
            textDisplayed = true;
        }
        else if ($('.tease.show').length > 0 && textDisplayed) {
            $assetsWrapper.find('.no-articles-info').remove();
            textDisplayed = false;
        }
    }

}