let $newsArchive = $('.page-template-news-archive .news-archive');

if($newsArchive.length > 0) {
    let $mobile = 'false';
    let $currentYear = $newsArchive.find('.years li .selected').data('slug');
    let container = $newsArchive.find('.container')[0];
    let $currentPage = 1;
    let $initialLoad = true;
    let $lang = $newsArchive.data('lang');
    if(window.innerWidth < 1200) {
        $mobile = 'true';
    }
    $newsArchive.find('.years li a').click(function(e) {
        e.preventDefault();
        $newsArchive.find('.years li a').removeClass('selected');
        $(this).addClass('selected');
        $currentYear = $(this).data('slug');
        $currentPage = 1;
        getPosts();
    });

    function setPagination() {
        $newsArchive.find('.pagination .page-numbers li a').click(function(e) {
            e.preventDefault();
            $currentPage = $(this).data('slug');
            getPosts();
        });
        $newsArchive.find('.pagination li.prev a').click(function(e) {
            e.preventDefault();
            $currentPage -= 1;
            getPosts();
        });
        $newsArchive.find('.pagination li.next a').click(function(e) {
            e.preventDefault();
            $currentPage += 1;
            getPosts();
        });
        if($currentPage > 1) {
            $newsArchive.find('.pagination li.prev').removeClass('disabled');
            $newsArchive.find('.pagination li.prev a').removeClass('disabled');
        }
        if($currentPage == $newsArchive.find('.pagination .page-numbers li').length) {
            $newsArchive.find('.pagination li.next').addClass('disabled');
            $newsArchive.find('.pagination li.next a').addClass('disabled');
        }
        $newsArchive.find('.pagination .page-numbers li').removeClass('current');
        if($currentPage > 7) {
            $newsArchive.find('.pagination .page-numbers li').eq(6).addClass('current');
        }
        else {
            $newsArchive.find('.pagination .page-numbers li').eq($currentPage - 1).addClass('current');
        }
    }

    function getPosts() {
        let data = new FormData();
        data.append('action', 'get_posts');
        data.append('year', $currentYear);
        data.append('page', $currentPage);
        data.append('mobile', $mobile);
        data.append('lang', $lang);
        $.ajax({
            type: 'POST',
            url: sval_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                container.innerHTML = res;
                setPagination();
                if(!$initialLoad) {
                    $(window).scrollTop($newsArchive.offset().top);
                }
                else {
                    $initialLoad = false;
                }         
            }
        });
    }
    getPosts();
}