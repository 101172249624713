// Smooth scroll to anchor links
$('a').click(function(e) {
    if(this.href.substring(this.href.indexOf('#') + 1) != 'content-section') {
        scrollToAnchor(this.href, e, 100);
    }
});

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }
    
    $('header').removeClass('nav-mobile-show');
    $('body').removeClass('no-scroll-y');
    $('header').find('.nav-wrapper').removeClass('open');
    $('header').find('.nav-menu').removeClass('set-height');

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        window.location.hash = '';
    }
}

window.scrollToAnchor = scrollToAnchor;

if (window.location.hash) {
    var target = window.location.hash,
    target = target.replace('#', '');
    window.location.hash = "";
    $('header').removeClass('nav-mobile-show');
    $('body').removeClass('no-scroll-y');
    $('header').find('.nav-wrapper').removeClass('open');
    $('header').find('.nav-menu').removeClass('set-height');
    setTimeout(function () {
        $('html,body').animate({
            scrollTop: $("#" + target).offset().top - 80
        }, 300);
    }, 900)
}