var navIcon = document.querySelector('.nav-wrapper');
var header = document.getElementsByTagName('header')[0];
var body = document.getElementsByTagName('body')[0];
let links, linkIcons, navMenu;
if(header) {
    links = header.querySelectorAll('.menu-content ul li a');
    linkIcons = header.querySelectorAll('.menu-content ul li svg');
    navMenu = header.querySelector('.nav-menu');
}
var mediumBreakpoint = 1024;

if(navMenu) {
    setTimeout(function() {
        navMenu.classList.add('anim');
    }, 500);
}
if (navIcon) {
    navIcon.addEventListener('click', function () {
        if (navIcon.classList.contains('open')) {
            navIcon.classList.remove('open');
            header.classList.remove('nav-mobile-show');
            body.classList.remove('no-scroll-y');
            setTimeout(function() {
                navMenu.classList.remove('set-height');
            }, 1000);
        } else {
            navIcon.classList.add('open');
            header.classList.add('nav-mobile-show');
            body.classList.add('no-scroll-y');
            navMenu.classList.add('set-height');
        }
    });
}

if (links && links.length > 0) {
    links.forEach(link => {
        let parent = link.closest('.menu-item-has-children');
        link.addEventListener('click', function(e) {
            if(window.innerWidth < mediumBreakpoint) {
                e.preventDefault();
            }
            if(parent.classList.contains('active')){
                window.location = e.target.href;
            }
            else {
                parent.classList.add('active');
            }
        });
    });
}

if (linkIcons && linkIcons.length > 0) {
    linkIcons.forEach(icon => {
        let parent = icon.parentNode;
        icon.addEventListener('click', function(e) {
            e.preventDefault();
            if(parent.classList.contains('active')){
                parent.classList.remove('active');
            }
            else {
                parent.classList.add('active');
            }
        });
    });
}

// Close the mobile menu if we resize to > medium breakpoint
/* $(window).resize(function() {
    if (navIcon.classList.contains('open') && window.innerWidth > mediumBreakpoint) {
        header.classList.remove('nav-mobile-show');
        body.classList.remove('no-scroll-y');
        navIcon.classList.remove('open');
    }
}) */
