export const intersectionObserve = function(elements, rootMargin, threshold, onIntersectionChange) {
    if ('IntersectionObserver' in window) {
        const options = {
            root: null, // relative to document viewport
            rootMargin: '0px 0px ' + (rootMargin || 0) + 'px', // margin around root. Values are similar to css property. Unitless values not allowed
            threshold: threshold || 0 // visible amount of item shown in relation to root
        };

        const _onIntersectionChange = function (changes, observer) {
            changes.forEach(change => {
                if (onIntersectionChange) {
                    onIntersectionChange(change.target, change.intersectionRatio);
                }
            });
        };

        const observer = new IntersectionObserver(_onIntersectionChange, options);

        if (elements.forEach) {
            elements.forEach(element => observer.observe(element));
        } else {
            observer.observe(elements);
        }
    } else {
        if (elements.forEach) {
            elements.forEach(element => onIntersectionChange(element));
        } else {
            onIntersectionChange(elements);
        }
    }
};

// Example on how to use observer:
/*
import { intersectionObserve } from "./_intersection-observer";

const elements = document.querySelectorAll('');

intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            el.classList.add('show');
        } 
    })(element);
});
*/