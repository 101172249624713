var $card = $('.card');
if ($card) {
    $card.hover(function () {
        var $image = $(this).parent().siblings('.image');
        if ($image.hasClass('hover')) {
            $image.removeClass('hover');
        } else {
            $image.addClass('hover');
        }
    });
}