import { intersectionObserve } from "./_intersection-observer";

const elements = document.querySelectorAll('section.key-numbers');

intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('anim')) {
            el.classList.add('anim');
            animNumbers();
        } 
    })(element);
});

function animNumbers() {
    $('.number span').each(function () {
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
    }