import Glide from "@glidejs/glide";

let $heroBlock = $('.block-hero');
$(window).on('load', function(){
    if($heroBlock.length > 0) {
        $('.block-hero').addClass('loaded');
    }
});

function initHeroSlider(id) {
    var imagesGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        autoplay: 12000,
        animationDuration: 50,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });
    let svg;
    if(window.innerWidth < 768) {
        svg = document.querySelector('svg.mobile');
    }
    else if(window.innerWidth < 1500) {
        svg = document.querySelector('svg.tablet');
    }
    else {
        svg = document.querySelector('svg.desktop');
    }
    let timeoutFade;
    let timeoutPause;
    let timeout;
    let timeoutUnpause;
    imagesGlide.on('mount.after', function() {
        if($('.block-hero .glide .glide__slide--active video').length > 0) {
            $('.block-hero .glide .glide__slide--active video').get(0).play();
        }
        if(svg) {
            svg.pauseAnimations();
            timeoutFade = setTimeout(function() {
                svg.classList.add('fade');
            }, 350);
            timeoutPause = setTimeout(function() {
                svg.unpauseAnimations();
            }, 1000);
            timeout = setTimeout(function() {
                svg.classList.remove('fade');
            }, 11350);
            timeoutUnpause = setTimeout(function() {
                svg.pauseAnimations();
            }, 11800);
        }
    });
    imagesGlide.on('run.after', function() {
        if($('.block-hero .glide .glide__slide--active:not(glide__slide--clone)').prev().find('video').length > 0) {
            setTimeout(function() {
                $('.block-hero .glide .glide__slide--active:not(glide__slide--clone)').prev().find('video').get(0).pause();
                $('.block-hero .glide .glide__slide--active:not(glide__slide--clone)').prev().find('video').get(0).currentTime = 0;
            }, 1000);
        }

        let $activeHasVideo = $('.block-hero .glide .glide__slide--active video');
        if($activeHasVideo.length > 0) {
            $activeHasVideo.get(0).play();
        }
        if(svg) {
            svg.classList.remove('fade');
            svg.pauseAnimations();
            clearTimeout(timeoutFade);
            clearTimeout(timeoutPause);
            clearTimeout(timeout);
            clearTimeout(timeoutUnpause);

            timeoutFade = setTimeout(function() {
                svg.setCurrentTime(0);
                svg.classList.add('fade');
            }, 350);
            timeoutPause = setTimeout(function() {
                svg.unpauseAnimations();
            }, 1000);

            timeout = setTimeout(function() {
                svg.classList.remove('fade');
            }, 11350);
            timeoutUnpause = setTimeout(function() {
                svg.pauseAnimations();
            }, 11800);
        }
    });
    let $images = $('#' + id);
    if ($images) {
        imagesGlide.mount();
        let glideIndex = $images.find('.glide-navigation .glide-index .index-number')[0];
        if(glideIndex) {
            imagesGlide.on('run', function (e) {
                let count;
                if(imagesGlide.index < 10) {
                    count = '0' + (imagesGlide.index + 1);
                }
                else {
                    count = '' + (imagesGlide.index + 1);
                }
                glideIndex.innerHTML = count;
            });
        }
        if(svg) {
            $images.hover(function() {
                clearTimeout(timeoutFade);
                clearTimeout(timeoutPause);
                clearTimeout(timeout);
                clearTimeout(timeoutUnpause);
                svg.classList.remove('fade');
                svg.pauseAnimations();
            }, function() {
                imagesGlide.go('=' + imagesGlide.index);
            });
        }
    }
}
window.initHeroSlider = initHeroSlider;