var $header = $('header');
var lastScrollTop = 0;
$(window).scroll(function(e) {
    var scrollTop = $(this).scrollTop();

    if (scrollTop === 0) {
        $header.removeClass('sticky');
    } else  {
        $header.addClass('sticky');
    }

    lastScrollTop = scrollTop;
});